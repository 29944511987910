<template>
    <div class="update-password bg-light text-primary">
        <img
            src="@/assets/cross.svg"
            class="icon-md"
            :style="{ justifySelf: 'end' }"
            @click="$router.replace('/login')"
        />
        <img src="@/assets/logo_primary.svg" />
        <h2>Please enter a new password</h2>
        <form class="gb-form gb-form--centered" @submit.prevent="start">
            <fieldset class="gb-form__fieldset gb-form__fieldset--centered">
                <label for="password">Password</label>
                <PasswordInput id="password" v-model="password" required />
            </fieldset>

            <small v-if="success" class="text-green" style="text-align: center;">
                Password has been successfully reset! You wil be redirected to the login page.
            </small>
            <small v-else-if="error" class="text--red" style="text-align: center;">
                {{ errorMsg }}
            </small>
            <small v-else style="text-align: center;">
                Use at least 8 character, one uppercase <span>and one number digit.</span>
            </small>
            <Button
                label="Update password"
                type="submit"
                primary
                fluid
                :disabled="success || loading"
            />
        </form>
    </div>
</template>

<script>
import PasswordInput from '@/components/inputs/PasswordInput'
import logger from '@/utils/logger.js'
export default {
    name: 'UpdatePassword',
    components: {
        PasswordInput
    },
    inject: ['updateUserPassword'],
    data() {
        return {
            password: '',
            success: false,
            error: false,
            loading: false,
            errorMsg: ''
        }
    },
    methods: {
        async start() {
            try {
                this.loading = true
                this.success = false
                this.error = false
                this.errorMsg = 'Something went wrong!'
                const result = await this.updateUserPassword(
                    this.getEmail(),
                    this.getToken(),
                    this.password
                )
                logger.log(result)
                const { errors, __typename: type } = result.data.updatePassword

                if (type === 'ResponseError') {
                    // Error handling
                    this.error = true
                    this.errorMsg = errors[0].message
                    logger.log(errors)
                } else if (type === 'UpdatePasswordResponse') {
                    // Success handling
                    this.success = true
                    const self = this
                    setTimeout(() => self.redirectToLogin(), 3000)
                }
            } catch (error) {
                throw new Error(error)
            } finally {
                this.loading = false
            }
        },
        getToken() {
            return this.$route.query.token
        },
        getEmail() {
            return this.$route.query.email
        },
        redirectToLogin() {
            this.$router.replace('/login')
        }
    }
}
</script>

<style lang="scss" scoped>
small span::before {
    content: '\A';
    white-space: pre;
}
.update-password {
    min-height: 100vh;
    padding: 1rem;
    display: grid;
    align-content: start;
    justify-items: center;
    row-gap: 1rem;
    padding-top: 4rem;

    form {
        max-width: 260px;
        box-sizing: content-box;
        fieldset {
            text-align: center;
        }
    }
}
</style>
